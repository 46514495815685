import React from "react";
import { Element } from "react-scroll";
import Works from "../sections/home/Works";
import PageWrapper from "../components/PageWrapper";


const EducationsPage = () => {
    return (
        <>
            <PageWrapper darkTheme>

            </PageWrapper>
        </>
    );
};

export default EducationsPage;
